import stateSetters from '@common/plugins/state-setters'
import Model from '@common/models/orm/Project'
import Workspace from '@common/models/orm/Workspace'
import { createApiUrl } from '@common/plugins/helpers'
import Story from '@common/models/orm/Story'
import Group from '@common/models/orm/Group'
import Contract from '@common/models/orm/Contract'
import Customer from '@common/models/orm/Customer'
import PaymentBearer from '@common/models/orm/PaymentBearer'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'

const defaultState = {
  current: null,
}

const urlParts = {
  anonymization: 'anonymization',
  settings: 'settings',
  chatbot: 'chatbot',
  uploadImage: 'upload-image',
  requestConsent: 'request-consent',
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) =>
      query().whereId(state.current).withAllRecursive(2).first(),
    getAll: (state, { query }) =>
      query().withAll().orderBy('name', 'ASC').get(),
    getByWorkspaceId: (state, { query }) => {
      return (workspaceId, sortBy = 'name', order = 'asc') =>
        query()
          .where('workspaceId', workspaceId)
          .withAllRecursive(3)
          .orderBy(sortBy, order)
          .get()
    },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById(_, id) {
      const url = createApiUrl(Model.entity, id)

      try {
        const { data } = await this.$axios.get(url).then(({ data }) => data)

        if (!data) {
          return
        }

        await this.$db().model(Model).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchAll({ dispatch }, workspaceId) {
      const suffix = Model.entity
      const url = createApiUrl(Workspace.entity, workspaceId, suffix)

      try {
        const { data } = await this.$axios
          .get(url, { expectList: true })
          .then(({ data }) => data)

        if (!Array.isArray(data)) {
          await this.$db().model(Workspace).dispatch('setChanging', false)

          return
        }

        await dispatch('insertOrUpdate', {
          entity: suffix,
          data,
        })

        await this.$db().model(Workspace).dispatch('setChanging', false)
      } catch (error) {
        throw new Error(error)
      }
    },
    async setCurrentTo({ state, commit }, id) {
      let projectExists = false

      if (state.data[id]) {
        projectExists = true
      }

      if (projectExists === false) {
        await Model.dispatch('fetchById', id)
      }

      commit('setCurrent', id)

      return state.data[id]
    },
    async fetchStories({ dispatch }, id) {
      const url = createApiUrl(Model.entity, id, Story.entity)

      try {
        const { data } = await this.$axios
          .get(url, { expectList: true })
          .then(({ data }) => data)

        if (!data) {
          return
        }

        await this.$db().model(Story).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchGroups({ dispatch }, id, entityType = 'story') {
      const url = createApiUrl(Model.entity, id, Group.entity)

      try {
        const { data } = await this.$axios
          .get(`settings/v1${url}?entityType=${entityType}`, {
            expectList: true,
          })
          .then(({ data }) => data)

        if (!data) {
          return
        }

        await Group.deleteAll()

        await this.$db().model(Group).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async translateAllStories({ dispatch }, formData) {
      const url = createApiUrl(Model.entity, formData.id, 'translate')

      const { data } = await this.$axios.$post(url, formData)

      return data
    },
    async fetchStoryLinkTargets({ dispatch }, storyId) {
      const { data } = await this.$db()
        .model(Story)
        .dispatch('fetchChatNodeData', {
          id: storyId,
          node: 'story-link',
          endpoint: 'options',
        })

      if (!data) {
        return
      }

      const targets = []

      for (const story of data) {
        targets.push({
          id: story.value,
          name: story.text,
          gotos: story.goto,
          isPublished: story.isPublished,
          isActive: story.isActive,
        })
      }

      // we create instead of insertOrUpdate to clear the outdated ones between stories
      await this.$db().model(StoryLinkTarget).create({
        data: targets,
      })

      return data
    },
    async fetchContract({ dispatch }, billingContractId) {
      await this.$db().model(Contract).dispatch('fetchById', billingContractId)
    },
    async fetchPaymentBearer({ dispatch }, id) {
      await this.$db().model(PaymentBearer).dispatch('fetchById', id)
    },
    async fetchBillingInformation({ dispatch }, id) {
      await this.$db().model(Customer).dispatch('fetchById', id)
    },
    async fetchChatbotSettingData({ dispatch }, id) {
      const url = createApiUrl(Model.entity, id, 'settings', 'chatbot')

      try {
        const { data } = await this.$axios.get(url).then(({ data }) => data)

        if (!data) {
          return
        }

        return data
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(Model.entity, formData.id)
      const { data } = await apiAction(url, formData)

      if (!data) {
        return {}
      }

      const { [Model.entity]: entities } = await this.$db()
        .model(Model)
        .insertOrUpdate({
          data,
        })

      return entities.shift()
    },
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    async mandatoryTfa({ dispatch }, { status, projectId }) {
      const url = createApiUrl(
        Model.entity,
        projectId,
        Model.endpointMandatoryTfa,
      )

      try {
        await this.$axios.post(url, { status })

        await Model.update({
          where: projectId,
          data: {
            mandatoryTfa: status,
          },
        })
      } catch (error) {
        throw error
      }
    },
    async uploadFile({ commit }, { id, file, fileSize }) {
      if (!file) {
        throw new Error('No file provided')
      }

      const url = createApiUrl(Model.entity, id, urlParts.uploadImage)
      const form = new FormData()

      form.append('file', file)

      if (fileSize) {
        form.append('size', fileSize)
      }

      const { data } = await this.$axios.post(url, form)

      return data
    },
    async deleteUnusedVariables({ dispatch }, { projectId }) {
      const url = createApiUrl(Model.entity, projectId, Model.endpointVariables)

      try {
        await this.$axios.delete(url)
      } catch (error) {
        throw error
      }
    },
    async updateSecurityData(_, { projectId, ...payload }) {
      const url = createApiUrl(
        Model.entity,
        projectId,
        urlParts.settings,
        urlParts.anonymization,
      )

      try {
        const { data } = await this.$axios
          .put(url, payload)
          .then(({ data }) => data)

        await Model.update({
          where: projectId,
          data,
        })
      } catch (error) {
        throw error
      }
    },
    async updateChatbotSettings(_, { projectId, ...payload }) {
      const url = createApiUrl(
        Model.entity,
        projectId,
        urlParts.settings,
        urlParts.chatbot,
      )

      try {
        const { data } = await this.$axios
          .put(url, payload)
          .then(({ data }) => data)

        await Model.update({
          where: projectId,
          data,
        })
      } catch (error) {
        throw error
      }
    },
    async updateChatbotSettingData({ dispatch }, data) {
      const url = createApiUrl(
        Model.entity,
        data.id,
        urlParts.settings,
        urlParts.chatbot,
      )

      try {
        await this.$axios.put(url, data.payload)
      } catch (error) {
        throw error
      }
    },
    async resetConsentData({ dispatch }, data) {
      const url = createApiUrl(
        Model.entity,
        data.id,
        urlParts.settings,
        urlParts.chatbot,
        urlParts.requestConsent,
      )

      try {
        await this.$axios.delete(url)
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}
