import { FEATURE_HIDE_USER_SESSION } from '~common/plugins/const'

export default async function ({ store, redirect, route }) {
  try {
    const projectId = route.params.id
    const project = await store.dispatch(
      'entities/projects/setCurrentTo',
      projectId,
    )

    const enabledFeatures = project?.enabledFeatures ?? []

    if (enabledFeatures.includes(FEATURE_HIDE_USER_SESSION)) {
      redirect('/')
    }
  } catch (e) {
    throw e
  }
}
