import stateSetters from '@common/plugins/state-setters'
import User from '@common/models/orm/User'
import Agreement from '@common/models/orm/Agreement'

const endPointLogin = '/users/login'
const endPointPrepareTfa = '/users/prepare-tfa'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByWorkspaceId:
      (state, { query }) =>
      (workspaceId) =>
        query().where('workspaceId', workspaceId).get(),
    getTfaConversion({ tfaConversion }) {
      return tfaConversion
    },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    login({ dispatch }, form) {
      try {
        return this.$axios.$post(endPointLogin, form).then(({ data }) => data)
      } catch (error) {
        throw error
      }
    },
    async fetchMe({ dispatch }) {
      const { data } = await this.$axios
        .get('/users/me')
        .then(({ data }) => data)

      await dispatch(
        'entities/insertOrUpdate',
        {
          entity: 'users',
          data,
        },
        {
          root: true,
        },
      )

      await dispatch('setCurrent', data.id)
    },
    async fetchById({ dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        { User, id },
        {
          root: true,
        },
      )
    },
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    async updateProfile({ dispatch }, form) {
      const { data } = await this.$axios.$put('/users/me', form)

      if (!data) {
        return {}
      }

      const { [User.entity]: entities } = await dispatch('insertOrUpdate', {
        entity: User.entity,
        data,
      })

      return entities.shift()
    },
    async fetchCompanyByWorkspace({ dispatch }, workspace) {
      const { data } = await this.$axios.$get(
        `/workspaces/${workspace}/company`,
      )

      return data
    },
    async updateCompanyByWorkspace({ dispatch }, payload) {
      const { data } = await this.$axios.$put(
        `/workspaces/${payload.workspace}/company`,
        payload.data,
      )

      return data
    },
    async registerByInvitation(
      { state, commit, dispatch },
      {
        firstName,
        lastName,
        password,
        token,
        preferredLanguage,
        isRegistrationRequired,
      },
    ) {
      try {
        const payload = isRegistrationRequired
          ? {
              token,
              preferredLanguage,
              firstName,
              lastName,
              password,
            }
          : { token }

        const url = isRegistrationRequired
          ? '/users/register-by-workspace-invite'
          : '/users/accept-workspace-invite'

        const { data } = await this.$axios.$post(url, payload)

        return data
      } catch (error) {
        throw error
      }
    },
    async changePassword({ dispatch }, { currentPassword, newPassword }) {
      await this.$axios.$put('/users/me/password', {
        password: currentPassword,
        newPassword,
      })
    },
    async changeEmail({ dispatch }, { newEmail, password }) {
      const { id, pendingEmail } = await this.$axios
        .$put('/users/me/email', {
          email: newEmail,
          password,
        })
        .then(({ data }) => data)

      await this.$db().model(User).update({
        where: id,
        data: { pendingEmail },
      })
    },
    async resendEmailConfirmation({ dispatch }, email) {
      await this.$axios.$put('/users/me/email', { email })
    },
    async fetchAgreements({ state }) {
      await this.$db().model(Agreement).dispatch('fetchByUser', state.current)
    },
    async fetchTfaStatus({ dispatch }) {
      try {
        const { data } = await this.$axios
          .get('/users/tfa-conversion')
          .then(({ data }) => data)

        const { tfaConversion } = data

        this.$db()
          .model(User)
          .commit((state) => (state.tfaConversion = tfaConversion))
      } catch (error) {
        throw error
      }
    },
    prepareTfa({ dispatch }, authMethod) {
      try {
        return this.$axios
          .$post('/users/prepare-tfa', authMethod)
          .then(({ data }) => data)
      } catch (error) {
        throw error
      }
    },
    activateTfa({ dispatch }, payload) {
      try {
        return this.$axios
          .$post('/users/activate-tfa', payload)
          .then(({ data }) => data)
      } catch (error) {
        throw error
      }
    },
    tfaLogin({ dispatch }, tfaData) {
      return this.$axios
        .$post('/users/tfa-login', tfaData)
        .then(({ data }) => data)
    },
    resendTfaToken({ dispatch }, { data, isEnableTfaPage }) {
      const endpoint = isEnableTfaPage ? endPointPrepareTfa : endPointLogin

      try {
        return this.$axios.$post(endpoint, data).then(({ data }) => data)
      } catch (error) {
        throw error
      }
    },
    tfaResetRequest({ dispatch }, payload) {
      return this.$axios
        .$post('/users/tfa-reset', payload)
        .then(({ data }) => data)
    },
    async tfaUnlock({ dispatch }, payload) {
      const data = await this.$axios.$post('/users/tfa-unlock', payload)

      payload.tfaStatus = User.TFA_ENABLED

      await this.$db().model(User).update({
        where: payload.id,
        data: payload,
      })

      return data
    },
    async tfaDeactivate({ dispatch }, userValue) {
      try {
        const data = await this.$axios.$get('/users/deactivate-tfa')

        const user = JSON.parse(JSON.stringify(userValue))

        user.tfaStatus = 'disabled'

        await this.$db().model(User).update({
          where: user.id,
          data: user,
        })

        return data
      } catch (error) {
        throw error
      }
    },
  },
}
