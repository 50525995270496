import { required, maxLength } from 'vuelidate/lib/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import { MODIFIER_UNTRANSLATABLE } from '@common/constants/chat-element-modifiers'
import Model from '@/models/chatElements/ChatElementBaseModel'

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

export default class ChatElementVariable extends Model {
  static className = 'ChatElementVariable'

  static entity = 'variable'

  static label = 'general.chat_element_variable'

  static formFields = ['variableValue']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      variableValue: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonVariable')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormVariable')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormVariable/ChatFormVariableTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/variable-icon.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_UNTRANSLATABLE]
  }

  static getFormValidations() {
    return {
      form: {
        variableValue: {
          required,
          maxLength: maxLength(10240),
        },
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        variableValue: {
          required,
          maxLength: maxLength(10240),
        },
      },
    }
  }

  static getUsedVariables({ variableValue }) {
    const variables = variableValue.matchAll(VAR_TAG_RE)

    return Array.from(variables).map(([, id]) => ({ id }))
  }

  props({ $store, node }) {
    const variables = $store.$db().model(ProjectVariable).all()

    const variableId = node.variableId
    const variable = variables.find((i) => i.id === variableId)

    return {
      variableName: variable?.name,
      variableValue: this.variableValue,
      variables,
    }
  }
}
